import {
  Avatar,
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { Control, UseFormWatch } from "react-hook-form";
import { TSale } from "../../../../core/types/Sale";
import TextFieldRequired from "../../../Inputs/TextFieldRequired";
import Validations from "../../../../core/functions/validations";
import { numberOnly } from "../../../../masks/masks";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TPhoneCode } from "../../../../core/types/Phone";
import NumberFunctions from "../../../../core/functions/NumberFunctions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 130,
      borderRadius: "4px",
    },
  },
};

function getStyles(isSelected: boolean, theme: Theme) {
  return {
    fontWeight: !isSelected
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  };
}

export interface ICheckoutClient5 {
  control: Control<TSale, any>;
  watch: UseFormWatch<TSale>;
  saveLostSale: () => void;
  clearErrors: () => void;
}

const CheckoutClient5 = ({
  control,
  watch,
  saveLostSale,
  clearErrors,
}: ICheckoutClient5) => {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [phoneCode, setPhoneCode] = React.useState<TPhoneCode>({
    ...NumberFunctions.phoneCodeBR,
  });

  const emailValue = watch("email");
  const phoneValue = watch("phone");

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Box
          sx={{
            backgroundColor: "rgba(8, 190, 75, 0.08)",
            width: 215,
            height: 40,
            borderRadius: 40,
            padding: "0px 16px 0px 0px",
            gap: 8,
          }}
        >
          <Stack direction={"row"} sx={{ alignItems: "center" }} spacing={1}>
            <Avatar sx={{ bgcolor: "rgba(8, 190, 75, 1)" }}>
              <Typography
                fontSize="16px"
                lineHeight="20px"
                fontWeight={700}
                color="rgba(255, 255, 255, 1)"
              >
                1
              </Typography>
            </Avatar>
            <Typography
              fontSize="16px"
              lineHeight="20px"
              fontWeight={700}
              color="rgba(52, 57, 72, 1)"
              sx={{ textTransform: "uppercase" }}
            >
              Dados Pessoais
            </Typography>
          </Stack>
        </Box>
      </Stack>

      <TextFieldRequired
        variant="labeled"
        size="small"
        control={control}
        fieldName="name"
        label="Nome Completo"
        placeholder="Digite seu nome completo"
        onFieldExit={saveLostSale}
        clearErrors={clearErrors}
        inputProps={{
          "data-cy": "customerName",
          "data-testid": "customerName",
        }}
        dataCyErrorLabel="customerNameError"
        startAdornment={
          <Box sx={{ marginRight: 1 }}>
            <img
              alt="name-info"
              src="/assets/icons/customer/nameComplete.svg"
            />
          </Box>
        }
      />
      <TextFieldRequired
        variant="labeled"
        size="small"
        control={control}
        fieldName="email"
        label="Email"
        placeholder="Digite seu email para receber a compra"
        onFieldExit={saveLostSale}
        clearErrors={clearErrors}
        validate={(value) =>
          !value || value == "" ? true : Validations.email(value)
        }
        inputProps={{
          "data-cy": "customerEmail",
          "data-testid": "customerEmail",
          style: { textTransform: "lowercase" },
        }}
        dataCyErrorLabel="customerEmailError"
        startAdornment={
          <Box sx={{ marginRight: 1 }}>
            <img alt="name-info" src="/assets/icons/customer/email.svg" />
          </Box>
        }
      />
      <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
        <TextFieldRequired
          variant="labeled"
          size="small"
          control={control}
          fieldName="document"
          label="CPF"
          placeholder="999.999.999-99"
          inputProps={{
            inputMode: "numeric",
            maxLength: 14,
            "data-cy": "customerDocument",
            "data-testid": "customerDocument",
          }}
          dataCyErrorLabel="customerDocumentError"
          clearErrors={clearErrors}
          validate={(value) =>
            !value || value == "" ? true : Validations.CPF(value)
          }
          startAdornment={
            <Box sx={{ marginRight: 1 }}>
              <img alt="name-info" src="/assets/icons/customer/document.svg" />
            </Box>
          }
        />

        <Box sx={{ display: { xs: "block", sm: "none" } }} />

        <TextFieldRequired
          variant="labeled"
          size="small"
          control={control}
          fieldName="phone"
          label="Celular com Whatsapp"
          placeholder="(99) 99999-9999"
          shrink={phoneValue !== ""}
          inputProps={{
            inputMode: "numeric",
            maxLength: 15,
            "data-cy": "customerPhone",
            "data-testid": "customerPhone",
          }}
          sxInputLabel={{
            ml: phoneValue === "" ? 14 : 3.5,
          }}
          dataCyErrorLabel="customerPhoneError"
          onFieldExit={saveLostSale}
          clearErrors={clearErrors}
          validate={(value) =>
            !value || value == "" ? true : numberOnly(value).length === 11
          }
          startAdornment={
            // <InputAdornment position="start">
            //   <Select
            //     labelId="select-phone-code-label"
            //     id="select-phone-code"
            //     variant="standard"
            //     size="small"
            //     autoWidth={false}
            //     disableUnderline={true}
            //     open={open}
            //     onClose={handleClose}
            //     onOpen={handleOpen}
            //     value={phoneCode}
            //     onChange={(event) => {
            //       const c = NumberFunctions.phoneCodes.find(
            //         (cv) => cv.code === event.target.value
            //       );
            //       if (c) {
            //         setPhoneCode(c);
            //       }
            //     }}
            //     renderValue={(selected) => (
            //       <Stack
            //         direction="row"
            //         spacing={0.5}
            //         alignContent="center"
            //         alignItems="center"
            //       >
            //         <Avatar
            //           alt={selected.countryName}
            //           src={selected.flag}
            //           sx={{ width: 24, height: 24 }}
            //         />
            //         <Typography>{selected.code}</Typography>
            //       </Stack>
            //     )}
            //     MenuProps={MenuProps}
            //     inputProps={{ sx: { padding: "0 !important" } }}
            //     IconComponent={() => (
            //       <IconButton
            //         size="small"
            //         onClick={() => setOpen(!open)}
            //         sx={{ width: 26, height: 26, ml: 0.4 }}
            //       >
            //         {open ? (
            //           <KeyboardArrowUpIcon color="disabled" />
            //         ) : (
            //           <KeyboardArrowDownIcon color="disabled" />
            //         )}
            //       </IconButton>
            //     )}
            //     style={{ borderRadius: "4px", paddingRight: "0px" }}
            //   >
            //     {NumberFunctions.phoneCodes.map((phone) => (
            //       <MenuItem
            //         key={phone.code}
            //         value={phone.code}
            //         style={getStyles(phone.code === phoneCode.code, theme)}
            //       >
            //         <Stack direction="row" spacing={2}>
            //           <Avatar
            //             alt={phone.countryName}
            //             src={phone.flag}
            //             sx={{ width: 24, height: 24 }}
            //           />
            //           <Typography fontWeight={500}>{phone.code}</Typography>
            //         </Stack>
            //       </MenuItem>
            //     ))}
            //   </Select>
            //   <Box
            //     sx={{
            //       backgroundColor: "#DCDDDE",
            //       height: "22.5px",
            //       width: "2px",
            //       mx: 1,
            //     }}
            //   />
            // </InputAdornment>

            <Box sx={{ marginRight: 1 }}>
              <img alt="name-info" src="/assets/icons/customer/phone.svg" />
            </Box>
          }
        />
      </Stack>
    </Stack>
  );
};

export default CheckoutClient5;
