import { Backdrop, CircularProgress, Stack, Typography } from "@mui/material"
import PaidIcon from "@mui/icons-material/Paid"
import React from "react"

const CheckoutSendingBackdrop = ({ open }: { open: boolean }) => {
  return (
    <Backdrop
      sx={{
        bgColor: "#3333",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
    >
      <Stack
        direction={"column"}
        width={"100%"}
        sx={{ p: 3 }}
        spacing={3}
        alignItems="center"
        justifyContent="center"
      >
        <PaidIcon fontSize="large" color="primary" />
        <Stack direction="column" alignItems="center">
          <Typography
            variant="body1"
            fontWeight={500}
            textAlign="center"
            data-cy="processingPayment"
            data-testid="processingPayment"
          >
            Estamos processando seu pagamento.
          </Typography>
          <Typography variant="body1" fontWeight={500} textAlign="center">
            Isso pode levar alguns segundos.
          </Typography>
        </Stack>
        <CircularProgress size="2rem" />
      </Stack>
    </Backdrop>
  )
}

export default CheckoutSendingBackdrop
