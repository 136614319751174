import { numberOnly } from "../../masks/masks"

const CPF = (cpf?: string) => {
  if (!cpf) {
    return false
  }

  cpf = numberOnly(cpf)

  if (cpf === "00000000000") {
    return false
  }

  var sum = 0
  var rest

  for (var i = 1; i <= 9; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
  }
  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) {
    rest = 0
  }
  if (rest !== parseInt(cpf.substring(9, 10))) {
    return false
  }

  sum = 0
  for (i = 1; i <= 10; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)
  }
  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) {
    rest = 0
  }
  if (rest !== parseInt(cpf.substring(10, 11))) {
    return false
  }
  return true
}

const email = (email?: string) => {
  if (!email) {
    return false
  }

  const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  return expression.test(email)
}

function hasUpperCaseAndNumber(input: string): boolean {
  const hasUpperCase = /[A-Z]/.test(input)
  const hasNumber = /[0-9]/.test(input)

  return hasUpperCase && hasNumber
}

const Validations = {
  CPF,
  email,
  hasUpperCaseAndNumber,
}

export default Validations
