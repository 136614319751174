import {
  Alert,
  Container,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material"
import {
  errorCheckoutPaymentPIX,
  methodTypeBoleto,
  methodTypePix,
  paymentsFailed,
  paymentsPaid,
} from "../../../core/constants"
import { PixSuccess } from "./PixSuccess"
import { BoletoSuccess } from "./BoletoSuccess"
import { useCallback, useEffect, useRef, useState } from "react"
import { IOrderCharge } from "../../../core/types/Order"
import { useLocalStorage } from "../../../core/hooks/useLocalStorage"
import { useNavigate, useParams } from "react-router-dom"
import OrderController from "../../../core/controllers/OrderController"
import Hosts from "../../../core/functions/HostFunctions"
import TagFunctions, { ITagsProp } from "../../../core/functions/TagFunctions"
import React from "react"
import { TSaleStorage } from "../../../core/types/Sale"
import HotjarFunctions from "../../../core/functions/HotjarFunctions"
import MetaFunctions from "../../../core/functions/MetaFunctions"
import GoogleFunctions from "../../../core/functions/GoogleFunctions"
import { useCheckoutContext } from "../../../core/hooks/useCheckoutContext"

export interface ISuccessOrderProps {
  paymentType: number
  QrCode?: string
  qrCodeUrl?: string
  urlRedirect?: string
  barCode?: string
  barCodeUrl?: string
  pdfUrl?: string
}

const SuccessOrder = () => {
  const { codeId } = useParams<{ codeId?: string }>()

  const { isFacebookPixelSent, addFacebookPixelSent } = useCheckoutContext()

  const googleAds = useRef<ITagsProp[] | null>(null)
  const tagInitialDataLayer = useRef<ITagsProp | null>(null)
  const tagDataLayer = useRef<ITagsProp | null>(null)
  const tagGTM = useRef<ITagsProp | null>(null)
  const tagsHotjar = useRef<Array<HTMLScriptElement | null>>([])

  const orderCharge = useRef<IOrderCharge | undefined>(undefined)
  const [sale, setSale] = useState<TSaleStorage | undefined>(undefined)

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState("")

  const [saleStorage, setSaleStorage] = useLocalStorage("afpa:sale", null)

  const navigate = useNavigate()

  const verifiedApproval = useCallback(
    async (chargeId?: string) => {
      var verifiedApprovalInterval = setInterval(async () => {
        if (chargeId) {
          const charge = await OrderController.verified({ chargeId })
          if (charge) {
            if (paymentsPaid.includes(charge.status)) {
              clearInterval(verifiedApprovalInterval)
              setSuccess(true)
              orderCharge.current = charge
            } else if (paymentsFailed.includes(charge.status)) {
              clearInterval(verifiedApprovalInterval)
              setSaleStorage(null)
              setError(errorCheckoutPaymentPIX)
            }
          }
        }
      }, 4000)
    },
    [sale]
  )

  const handleChangeSuccessClose = () => {
    if (orderCharge.current) {
      Hosts.openNavigate(`/${codeId}/success`, navigate)
    }
    setSuccess(false)
  }

  const handleChangeErrorClose = () => {
    Hosts.openNavigate(`/${codeId}`, navigate)
    setError("")
  }

  useEffect(() => {
    if (saleStorage === null) {
      Hosts.openNavigate(`/${codeId}`, navigate)
      return
    }

    setSale(saleStorage)
    verifiedApproval(saleStorage.chargeId)

    if (tagsHotjar.current.length <= 0) {
      tagsHotjar.current = HotjarFunctions.addTagsHotjar()
    }

    if (!tagInitialDataLayer.current) {
      tagInitialDataLayer.current = GoogleFunctions.initialDataLayer()
    }

    return () => {
      if (tagInitialDataLayer.current) {
        TagFunctions.removeTags(tagInitialDataLayer.current)
      }
    }
  }, [])

  useEffect(() => {
    if (sale) {
      if (!isFacebookPixelSent(sale.orderCode, "purchase")) {
        MetaFunctions.addTagPurchase(sale)
        MetaFunctions.sendPurchaseAPI(sale)
        addFacebookPixelSent(sale.orderCode, "purchase")
      }

      if (!googleAds.current) {
        googleAds.current = GoogleFunctions.addTagGoogleAdsPixelPurchase(sale)
      }

      if (!tagDataLayer.current) {
        tagDataLayer.current = GoogleFunctions.addDataLayerPurchase(sale)
      }
      if (!tagGTM.current) {
        tagGTM.current = GoogleFunctions.addGoogleTagManager(
          sale.googleTagManagerId
        )
      }
    }
    return () => {
      if (tagDataLayer.current) {
        TagFunctions.removeTags(tagDataLayer.current)
      }
      if (tagGTM.current) {
        TagFunctions.removeTags(tagGTM.current)
      }
    }
  }, [sale])

  return (
    <Container component={"main"} maxWidth="md">
      <Stack direction={"column"} spacing={2}>
        <Paper
          square={true}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            minHeight: "30rem",
            borderTopLeftRadius: 2,
            borderTopRightRadius: 2,
            padding: "2rem",
            mt: 2,
          }}
        >
          <Typography
            variant="h5"
            textAlign="center"
            sx={{ mb: 2 }}
            data-cy="paymentSuccess"
          >
            O seu pedido foi processado com sucesso!
          </Typography>
          {sale && sale.paymentType === methodTypePix && (
            <PixSuccess
              qrCode={sale.code || ""}
              qrCodeUrl={sale.codeUrl || ""}
            />
          )}
          {sale && sale.paymentType === methodTypeBoleto && (
            <BoletoSuccess
              barCode={sale.code || ""}
              barCodeUrl={sale.codeUrl || ""}
              pdfUrl={sale.codePdf || ""}
              redirectUrl={sale.redirectUrl}
            />
          )}
        </Paper>

        <Snackbar
          open={error !== ""}
          autoHideDuration={4000}
          onClose={handleChangeErrorClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleChangeErrorClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>

        <Snackbar
          open={success}
          autoHideDuration={3000}
          onClose={handleChangeSuccessClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleChangeSuccessClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Pagamento aprovado
          </Alert>
        </Snackbar>
      </Stack>
    </Container>
  )
}

export default SuccessOrder
