import { Alert, Box, Button, Snackbar, Stack, TextField, Typography } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState } from "react";
import Hosts from "../../../../core/functions/HostFunctions";
import React from "react";

export const PixSuccess = ({ qrCode, qrCodeUrl }: { qrCode: string, qrCodeUrl: string }) => {
  const [copied, setCopied] = useState(false);

  const handleClick = async () => {
    await Hosts.copyTextToClipboard(qrCode);
    setCopied(true);
  }

  return (
    <Stack direction={"column"} spacing={2} alignItems="center" justifyContent="center">
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1.5rem"
      }}>
        <img src={qrCodeUrl} alt="qrCode" />
        <TextField
          fullWidth
          aria-readonly
          size="small"
          value={qrCode}
          maxRows={1}
          inputProps={{
            "data-testid": "paymentQrCode"
          }}
        />
        <Button
          variant="contained"
          onClick={handleClick}
          startIcon={<ContentCopyIcon />}
          data-testid="buttonCopyQrCode"
        >
          Copiar código
        </Button>
      </Box>
      <Box>
        <Typography variant="body2">1. <strong>Aponte seu celular para essa tela</strong> para capturar o código</Typography>
        <Typography variant="body2">2. Abra seu <strong>aplicativo de pagamentos</strong></Typography>
        <Typography variant="body2">3. Confirme os dados e <strong>finalize o pagamento pelo aplicativo</strong></Typography>
        <Typography variant="body2">4. Será enviada uma <strong>confirmação de compra</strong> para você</Typography>
      </Box>

      <Alert sx={{ my: 1 }} severity="info">
        Você também pode finalizar o pagamento copiando e colando o código em seu aplicativo.
      </Alert>

      <Snackbar
        open={copied}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        message="Código copiado"
        autoHideDuration={2000}
        onClose={() => setCopied(false)}
      >
        <Alert
          variant="filled"
          onClose={() => setCopied(false)}
          severity="info"
          sx={{ width: '100%' }}
        >
          Código copiado!
        </Alert>
      </Snackbar>
    </Stack>
  );
}