import { Chip, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import {
  IProductPricePaymentInstallment,
  TProductFrequency,
  TProductTypePayment,
} from "../../../../../core/types/Product";
import { methodTypeCreditCard } from "../../../../../core/constants";
import Convertions from "../../../../../core/functions/convertions";
import NumberFunctions from "../../../../../core/functions/NumberFunctions";

export interface IOrderProduct5Prop {
  paymentMethodType: number;
  installmentNumber: number;
  description: string;
  value: number;
  defaultValue: number;
  installments?: IProductPricePaymentInstallment[] | null;
  subtitle?: boolean;
  typePayment?: TProductTypePayment;
  frequency?: TProductFrequency;
}

const OrderItem5 = ({
  paymentMethodType,
  installmentNumber,
  description,
  value,
  installments,
  subtitle,
  defaultValue,
  typePayment,
  frequency,
}: IOrderProduct5Prop) => {
  const isSignature = typePayment === TProductTypePayment.RecurringSubscription;

  if (paymentMethodType === methodTypeCreditCard) {
    var installmentValue = defaultValue;

    if (installments && installments !== null) {
      const installment = installments.find(
        (i) => i.installmentNumber === installmentNumber
      );
      if (installment) {
        installmentValue = installment.value;
      }
    }

    var complementValue = "";
    const frequencyString = isSignature
      ? Convertions.productFrequencyToType(frequency)
      : "";

    if (frequencyString !== "") {
      complementValue = `/${frequencyString}`;
    }

    return (
      <Stack direction="row" spacing={1}>
        <Typography
          variant={subtitle ? "subtitle1" : "body2"}
          fontWeight={subtitle ? 700 : 600}
          lineHeight={subtitle ? "32px" : "18px"}
          fontSize={subtitle ? "16px" : "14px"}
          sx={{ color: "rgba(28, 82, 189, 1)" }}
        >
          {description}
        </Typography>
        <Stack
          direction={{ xs: "column-reverse", sm: "row" }}
          spacing={1}
          alignContent={{ xs: "end", sm: "center" }}
          alignItems={{ xs: "end", sm: "center" }}
          justifyContent="end"
        >
          {isSignature && !subtitle && (
            <Chip
              label="Assinatura"
              variant="outlined"
              size="small"
              sx={{ borderRadius: "32px", gap: "10px", color: "#707275" }}
              style={{
                borderColor: "#D8D9FF",
                fontSize: "14",
                lineHeight: "18px",
              }}
            />
          )}

          <Typography
            variant={subtitle ? "subtitle1" : isSignature ? "body2" : "body1"}
            fontWeight={subtitle ? 700 : 500}
            lineHeight={subtitle ? "32px" : "18px"}
            fontSize={subtitle ? "16px" : "14px"}
            textAlign="right"
            sx={{ color: "rgba(28, 82, 189, 1)" }}
          >
            {subtitle || !isSignature
              ? `${installmentNumber}x de ${NumberFunctions.formatMoneyDefault(
                  installmentValue
                )}${complementValue}`
              : `${NumberFunctions.formatMoneyDefault(
                  installmentValue
                )}${complementValue}`}{" "}
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack direction="row" spacing={1}>
      <Typography
        variant={subtitle ? "subtitle1" : "body2"}
        fontWeight={subtitle ? 700 : 600}
        lineHeight={subtitle ? "32px" : "18px"}
        fontSize={subtitle ? "16px" : "14px"}
        sx={{ color: "rgba(28, 82, 189, 1)" }}
      >
        {description}
      </Typography>
      <Typography
        fontWeight={subtitle ? 700 : 500}
        textAlign="right"
        lineHeight={subtitle ? "32px" : "18px"}
        fontSize={subtitle ? "16px" : "14px"}
        sx={{ color: "rgba(28, 82, 189, 1)" }}
      >
        {NumberFunctions.formatMoneyDefault(value)}
      </Typography>
    </Stack>
  );
};

export default OrderItem5;
