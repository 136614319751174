import { Chip, Grid, Stack, Typography } from "@mui/material"
import React from "react"
import {
  IProductPricePaymentInstallment,
  TProductFrequency,
  TProductTypePayment,
} from "../../../../../core/types/Product"
import { methodTypeCreditCard } from "../../../../../core/constants"
import Convertions from "../../../../../core/functions/convertions"
import NumberFunctions from "../../../../../core/functions/NumberFunctions"

export interface IOrderProductProp {
  paymentMethodType: number
  installmentNumber: number
  description: string
  value: number
  defaultValue: number
  installments?: IProductPricePaymentInstallment[] | null
  subtitle?: boolean
  typePayment?: TProductTypePayment
  frequency?: TProductFrequency
}

const OrderItem4 = ({
  paymentMethodType,
  installmentNumber,
  description,
  value,
  installments,
  subtitle,
  defaultValue,
  typePayment,
  frequency,
}: IOrderProductProp) => {
  const isSignature = typePayment === TProductTypePayment.RecurringSubscription

  if (paymentMethodType === methodTypeCreditCard) {
    var installmentValue = defaultValue

    if (installments && installments !== null) {
      const installment = installments.find(
        (i) => i.installmentNumber === installmentNumber
      )
      if (installment) {
        installmentValue = installment.value
      }
    }

    var complementValue = ""
    const frequencyString = isSignature
      ? Convertions.productFrequencyToType(frequency)
      : ""
    if (frequencyString !== "") {
      complementValue = `/${frequencyString}`
    }

    return (
      //credit card
      <Grid container>
        <Grid item xs={7}>
          <Stack justifyContent="center" height="100%">
            <Typography
              variant={subtitle ? "subtitle1" : "body2"}
              fontWeight={subtitle ? 700 : 600}
              lineHeight={subtitle ? "20px" : "18px"}
              fontSize={subtitle ? "16px" : "14px"}
              sx={{ color: subtitle ? "#1C52BD" : "#707275" }}
            >
              {description}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <Stack
            direction={{ xs: "column-reverse", sm: "row" }}
            spacing={1}
            alignContent={{ xs: "end", sm: "center" }}
            alignItems={{ xs: "end", sm: "center" }}
            justifyContent="end"
          >
            {isSignature && !subtitle && (
              <Chip
                label="Assinatura"
                variant="outlined"
                size="small"
                sx={{ borderRadius: "32px", gap: "10px", color: "#707275" }}
                style={{
                  borderColor: "#D8D9FF",
                  fontSize: "14",
                  lineHeight: "18px",
                }}
              />
            )}

            <Typography
              variant={subtitle ? "subtitle1" : isSignature ? "body2" : "body1"}
              fontWeight={subtitle ? 700 : 500}
              lineHeight={subtitle ? "20px" : "18px"}
              fontSize={subtitle ? "16px" : "14px"}
              textAlign="right"
              sx={{ color: subtitle ? "#1C52BD" : "#707275" }}
            >
              {`${installmentNumber}x de ${NumberFunctions.formatMoneyDefault(
                installmentValue
              )}${complementValue}`}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container>
      <Grid item xs={7}>
        <Typography
          variant={subtitle ? "subtitle1" : "body2"}
          fontWeight={subtitle ? 700 : 600}
          lineHeight={subtitle ? "20px" : "18px"}
          fontSize={subtitle ? "16px" : "14px"}
          sx={{ color: subtitle ? "#1C52BD" : "#707275" }}
        >
          {description}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography
          fontWeight={subtitle ? 700 : 500}
          textAlign="right"
          lineHeight={subtitle ? "20px" : "18px"}
          fontSize={subtitle ? "16px" : "14px"}
          sx={{ color: subtitle ? "#1C52BD" : "#707275" }}
        >
          {NumberFunctions.formatMoneyDefault(value)}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default OrderItem4
